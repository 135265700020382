<template>
    <div class="container">
        <div class="content">
            <!-- <img class="bg" src="@/assets/imgs/Anniversary/bg.png" alt="" /> -->
            <headerWX class="headerWX"></headerWX>


            <!-- 收藏家奖励 -->
            <div class="collector_award">
                <!-- 排行榜 -->
                <div class="ranking_list">
                    <img src="@/assets/imgs/Anniversary/collector_award_ranking_list.png" alt="">
                    <div class="list_item">
                        <div class="item" v-for="(item, index) in rankInfoList" :key="index">
                            NO{{ item.rank }}. {{ item.usernick }}
                        </div>
                    </div>
                </div>
                <!-- 奖励 -->
                <div class="award">
                    <img src="@/assets/imgs/Anniversary/collector_award_award.png" alt="">
                    <div class="award_item">
                        <!-- 收藏进度 -->
                        <div class="award_item_left">
                            <div class="item" v-for="(item, index) in list.collectActivityConfigList" :key="index">
                                {{ item.actName }}<span>{{ item.processTxt }}</span>
                            </div>
                        </div>
                        <!-- 奖励 -->
                        <div class="award_item_right">
                            <div class="item" v-for="(item, index) in list.collectActivityConfigList" :key="index">
                                <div class="award_item_right_img">
                                    <div class="item" v-for="(prize, index) in item.prizeList" :key="index">
                                        <el-tooltip v-if="prize.prizeName" class="box-item" effect="light" :content="prize.prizeName" placement="top">
                                            <img :src="prize.prizeImg" alt="">
                                        </el-tooltip>
                                        <img v-else :src="prize.prizeImg" alt="">
                                        <p>&nbsp; X {{ prize.prizeNum }}</p>
                                    </div>
                                </div>
                                <div class="award_item_right_btn">
                                    <img v-if="item.btnText == '已领取'"
                                        src="@/assets/imgs/Anniversary/collector_award_award_btn_over.png" alt="" />
                                    <img v-else-if="item.isClick == 1" @click="award_item(index)"
                                        src="@/assets/imgs/Anniversary/collector_award_award_btn_get.png" alt="" />
                                    <!-- <img v-else-if="item.isClick == 0 && item.btnUrl !== ''" @click="go(item)"
                                        src="@/assets/imgs/NationalDay/go.png" alt="" /> -->
                                    <img v-else-if="item.isClick == 0 && item.btnUrl == null"
                                        src="@/assets/imgs/Anniversary/collector_award_award_btn_get.png" alt=""
                                        style="filter: grayscale(100%);" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- 藏品目录 -->
            <div class="object_catalogue">
                <img src="@/assets/imgs/Anniversary/object_catalogue.png" alt="">
                <div class="item">
                    <div class="item_item" v-for="(item, index) in data.itemResponseList" :key="item.itemId">
                        <div class="item_item_naem">{{ item.name }}</div>
                        <div class="item_item_icon">
                            <img :src="item.itemImg" alt="">
                        </div>
                        <div class="item_item_btn">
                            <img v-if="item.status == 0" src="@/assets/imgs/Anniversary/object_catalogue_btn_add.png"
                                alt="">
                            <img v-else-if="item.status == 1"
                                src="@/assets/imgs/Anniversary/object_catalogue_btn_over.png" alt="">
                        </div>
                    </div>
                </div>

            </div>


            <!-- 每日任务 -->
            <div class="everyday_task">
                <img src="@/assets/imgs/Anniversary/everyday_task.png" alt="" />

                <div class="task_Bady">
                    <!-- 文字提示 -->
                    <div class="task_Bady_text">
                        <div class="item" v-for="(item, index) in list.dayActivityConfigList" :key="item.actId">
                            <!-- <div class="item" v-for="(item, index) in 3" :key="index"> -->
                            <p>{{ item.actName }} <span>{{ item.processTxt }}</span></p>
                        </div>
                    </div>
                    <!-- 奖励图 -->
                    <div class="task_Bady_icon">
                        <div class="top_r_l">
                            <div class="item" v-for="(item, index) in list.dayActivityConfigList" :key="index">
                                <img src="@/assets/imgs/Anniversary/gold_icon.png" alt="" />
                                <p>X {{ item.prizeNum }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- 奖励按钮 -->
                    <div class="task_Bady_btn">
                        <div class="top_r_r">
                            <div class="item" v-for="(item, index) in list.dayActivityConfigList" :key="index">
                                <img v-if="item.btnText == '已领取'" src="@/assets/imgs/Anniversary/btn_alreadyFinish.png"
                                    alt="" />
                                <img v-else-if="item.isClick == 1" @click="receive(item)"
                                    src="@/assets/imgs/Anniversary/btn_finish.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                    src="@/assets/imgs/Anniversary/btn_goFinish.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                    src="@/assets/imgs/Anniversary/btn_finish.png" alt=""
                                    style="filter: grayscale(100%);" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 剩余 总共 使用-->
            <div class="number">
                <img src="@/assets/imgs/Anniversary/Number.png" alt="">
                <div class="numberBady">
                    <div class="n_l">{{ data.totalData.surplusNum }}</div>
                    <div class="n_c">{{ data.totalData.useNum }}</div>
                    <div class="n_r">{{ data.totalData.totalNum }}</div>
                </div>
            </div>

            <!-- 追加任务 -->
            <div class="add_task">
                <img src="@/assets/imgs/Anniversary/add_task.png" alt="" />
                <div class="add_task_Bady">
                    <div class="c_l">
                        <div class="item" v-for="(item, index) in list.activityConfigList" :key="index">
                            {{ item.actName }}<span> {{ item.processTxt }}</span>

                        </div>
                    </div>
                    <div class="c_r">
                        <div class="c_r_l">
                            <div class="item" v-for="(item, index) in list.activityConfigList" :key="index">

                                <!-- <div v-for="(img, i) in item.prizeList" :key="i" style="display: flex;">
                                    <img :src="img.prizeImg" alt="">
                                    <p> X {{ img.prizeNum }}</p>
                                </div> -->

                                <div style="display: flex;">
                                    <img src="@/assets/imgs/Anniversary/gold_icon.png" alt="">
                                    <p> X {{ item.prizeNum }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="c_r_r">
                            <div class="item" v-for="(item, index) in list.activityConfigList" :key="index">
                                <img v-if="item.btnText == '已领取'" src="@/assets/imgs/Anniversary/btn_alreadyFinish.png"
                                    alt="" />
                                <img v-else-if="item.isClick == 1" @click="receive(item)"
                                    src="@/assets/imgs/Anniversary/btn_finish.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                    src="@/assets/imgs/Anniversary/btn_goFinish.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                    src="@/assets/imgs/Anniversary/btn_finish.png" alt=""
                                    style="filter: grayscale(100%);" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <!-- 活动商店 -->
            <div class="shop">
                <div class="tit">
                    <img src="@/assets/imgs/Anniversary/shop_tit.png" alt="" />
                </div>
                <div class="shopBady">
                    <!-- <div class="item" v-for="(item, index) in getActivityData" :key="index"> -->
                    <div class="item" v-for="(item, index) in data.goodsInfoList" :key="index">
                        <img src="@/assets/imgs/Anniversary/shop_bady.png" alt="">
                        <img v-if="item.status == 2" src="@/assets/imgs/NationalDay/shop_btn_end.png" />
                        <img v-else-if="item.status == 0" style="filter: grayscale(100%)"
                            src="@/assets/imgs/NationalDay/shop_btn_get.png" alt="" />
                        <img v-else-if="item.status == 1" src="@/assets/imgs/NationalDay/shop_btn_get.png"
                            @click="goShop_pop(item)" alt="" />
                        <div class="item_number">
                            ({{ item.buyCount ? item.buyCount : 0 }}/{{
                                item.limitCount ? item.limitCount : 0
                            }})
                        </div>

                        <div class="itemBady">
                            <div class="itemBady_t">
                                <!-- <img src="@/assets/imgs/NationalDay/gold_icon.png" alt=""> -->
                                <p> &nbsp;X {{ item.goodsPrice }}</p>
                            </div>
                            <div class="itemBady_c">
                                <img :src="item.goodsImg" alt="">
                            </div>
                            <div class="itemBady_b">{{ item.goodsName }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 活动说明 -->
            <div class="explain">
                <div>
                    <img src="@/assets/imgs/Anniversary/explain.png" alt="">
                    <span
                        style="color: #7129ff; font-size: 16px; font-weight: bold;padding-top: 5px;">2025年4月1日—2025年4月21日24点</span>
                </div>

                <div>
                    <p> 1）玩家获取指定道具并发送至游戏中，即可完成任务要求。</p>
                    <p>2）依据完成的收藏任务数量，玩家可领取相应奖励，回馈奖励仅限一次。 </p>
                    <p>3）收藏任务相关道具可通过各种不同途径获取，耐心和专注会让你抢先一步。 </p>
                    <p>4）玩家完成每日任务可收集周年庆纪念币，周年庆特别纪念商店不会让你失望。 </p>
                    <p>5）完成所有收藏任务的玩家还将获得活动限定称号—大收藏家。 </p>
                    <p>6）活动结束时活动排行榜的排名前三的玩家将获得丰厚奖励，具体如下： </p>
                    <p style="text-indent: 30px">第一名：紫月盈榻；愿望清单X1；歌曲定制礼包X1</p>
                    <p style="text-indent: 30px">第二名：紫月盈榻；周年庆的铂金宝箱X1</p>
                    <p style="text-indent: 30px">第三名：紫月盈榻；周年庆的黄金宝箱X1</p>


                    <p style="color: red;font-weight: 900;font-size: 24px;">温馨提示：</p>
                    <p> 1）活动限定称号活动结束后发放</p>
                    <p>2）活动奖励请及时领取，活动结束后未领取奖励将无法继续领取，逾期不补。</p>
                    <p>3）歌曲定制礼包包含10首定制歌曲，由用户提供，但需符合相应版权要求以及法律法规，相关制作流程大约需要30个工作日。</p>
                    <p>4）获得愿望清单的玩家可获得指定道具一件包括但不限于限定获取道具。</p>
                    <p>5）获得周年庆的铂金宝箱的玩家可从历届幸运盲盒旷世珍宝奖池中获取指定道具一件。</p>
                    <p>6）获得周年庆的黄金宝箱的玩家可从当期幸运盲盒旷世珍宝奖池中获取指定道具一件。</p>
                </div>
            </div>

            <footerWX class="footerWX"></footerWX>

        </div>

        <!-- 二次确认弹窗 -->
        <div id="mask" v-if="ispopUp">
            <div style="
            position: fixed;
            top: 13%;
            left: 35%;
            border-radius: 10px;
            width: 30vw;
            height: 70vh;
            min-width: 600px;
            padding: 5px;
            background: linear-gradient(#d7b7ff, #f9f8ff);
            z-index: 9999;
          ">
                <popUp @ispopUp="TzpopUp" propShow="3" :DBF_goShop="DBF_goShop"></popUp>
            </div>
        </div>
    </div>
</template>

<script>
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import popUp from "@/components/pop_up";

export default {
    name: "QixiIndex",
    components: {
        headerWX,
        footerWX,
        popUp
    },
    data() {
        return {
            // 排行榜
            rankInfoList: [],
            // 商品数据
            getActivityData: {},

            data: {
                totalData: { surplusNum: 0, totalNum: 0, useNum: 0 }
            },
            list: [],

            ispopUp: false,
            DBF_goShop: "",
        };
    },
    created() {
        this.init()
    },
    mounted() { },
    methods: {
        // 收藏进度领取
        award_item(index) {

            axios({
                method: "post",
                url: "/activity/getExtActivityPrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    name: "2025celebrate_activity",
                    taskId: index + 1,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },

        go(item) {
            if (item.btnUrl.indexOf("http") != -1) {
                window.open(item.btnUrl, "_blank");
            } else {
                this.$router.push({
                    path: item.btnUrl,
                });
            }
        },

        // 领取奖励
        receive(item) {
            console.log(item, '111');

            axios({
                method: "post",
                url: "/activity/getActivityPrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    taskId: item.actId,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        // 确认兑换二次提示
        goShop_pop(item) {
            // 赋值要兑换的商品信息
            this.DBF_goShop = item;

            // 弹窗打开
            this.ispopUp = true;
        },

        // 关闭蒙层
        TzpopUp(msg) {
            if (msg.data == true && msg.type == 3) {
                // this.hintSucceed = true
                // this.submit()
                // console.log('关闭弹窗', this.DBF_goShop);
                this.shopBuy(this.DBF_goShop);
            } else if (msg.data == false && msg.type == 3) {
                // this.$message.info('已取消')
            }
            this.ispopUp = false;
        },
        // 商品二次确定后购买
        shopBuy(item) {
            // console.log('购买', item.goodsId);

            axios({
                method: "post",
                url: "/activity/buyActivityGoods",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025celebrate_activity",
                    goodsId: item.goodsId,
                    goodsNum: 1,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        // console.log(res.data);
                        this.$message.success(res.data.message);

                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        // 初始化
        init() {
            // 
            axios({
                method: "post",
                url: "/activity/getActivityList",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025celebrate_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.list = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });

            // 
            axios({
                method: "post",
                url: "/activity/getActivityData",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025celebrate_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.data = res.data.data
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });

            // 排行
            axios({
                method: "post",
                url: "/activity/getActivityRank",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025celebrate_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.rankInfoList = res.data.data.rankInfoList

                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.container {
    min-width: 1200px;
    background-color: #352273;

    .content {
        margin: 0 auto;
        max-width: 2000px;
        width: 100%;
        position: relative;
        background-image: url('../../assets/imgs/Anniversary/bg.png');
        // top: -100px;

        .bg {
            width: 100%;
            position: absolute;
            // top: 0;
        }
    }

    // 收藏家奖励
    .collector_award {
        width: 65%;
        margin: auto;
        display: flex;
        justify-content: center;

        .ranking_list {
            position: relative;

            .list_item {
                position: absolute;
                top: 105px;
                left: 50%;
                transform: translateX(-50%);
                width: 84%;
                height: 70.5%;

                // background-color: rgba(0, 255, 255, 0.527);
                .item {
                    height: 10%;
                    line-height: 45px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 800;
                    color: #6f00ff;
                }
            }
        }

        .award {
            position: relative;

            .award_item {
                position: absolute;
                top: 110px;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                height: 76%;
                display: flex;

                .award_item_left {
                    flex: 5;

                    // background-color: #a401018a;
                    .item {
                        color: #6f00ff;
                        height: 20.5%;
                        line-height: 98px;
                        text-align: center;
                        font-size: 24px;
                    }
                }

                .award_item_right {
                    flex: 5;

                    .item {
                        height: 20.5%;

                        display: flex;

                        .award_item_right_img {
                            flex: 5;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .item {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                                p {
                                    color: #6f00ff;
                                }


                            }
                        }

                        .award_item_right_btn {
                            flex: 5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                }
            }
        }
    }

    // 藏品目录
    .object_catalogue {
        width: fit-content;
        margin: auto;
        position: relative;

        .item {
            position: absolute;
            top: 132px;
            left: 50%;
            transform: translateX(-50%);
            width: 92%;
            height: 62%;
            display: flex;
            justify-content: center;

            .item_item {
                width: 20%;
                height: 100%;
                border: 1px solid #ff000000;

                >div {
                    display: flex;
                    height: 33%;
                    justify-content: center;
                    align-items: center;
                }

                .item_item_naem {
                    font-size: 24px;
                    font-weight: 900;
                    color: #6f33fb;
                }

                .item_item_icon {
                    height: 34%;

                    img {
                        max-width: 80px;
                    }
                }

                .item_item_btn {
                    img {
                        max-width: 104px;
                        max-height: 35px;
                    }
                }
            }
        }
    }


    // 每日任务
    .everyday_task {
        width: fit-content;
        margin: auto;
        position: relative;

        .task_Bady {
            position: absolute;
            top: 27%;
            left: 50%;
            transform: translateX(-50%);
            width: 92%;
            height: 63%;
            display: flex;

            .task_Bady_text {
                flex: 34%;

                .item {
                    height: 34%;

                    p {
                        font-size: 28px;
                        line-height: 98px;
                        text-align: center;
                        color: #6f33fb;
                        font-weight: 700;
                    }
                }
            }

            .task_Bady_icon {
                flex: 34%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .top_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;
                        justify-content: center;

                        p {
                            font-size: 20px;
                            line-height: 50px;
                            color: #6f33fb;
                            font-weight: 700;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            padding: 0 10px;
                        }
                    }
                }
            }

            .task_Bady_btn {
                flex: 33%;
                display: flex;


                .top_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        text-align: center;
                    }
                }
            }
        }
    }

    // 剩余 总共 使用
    .number {
        width: fit-content;
        margin: auto;
        position: relative;

        .numberBady {
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            height: 36%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            >div {
                font-size: 32px;
                font-weight: bold;
                color: #6f33fb;
            }
        }
    }

    // 追加任务
    .add_task {
        width: fit-content;
        position: relative;
        margin: auto;

        .add_task_Bady {
            position: absolute;
            top: 33%;
            left: 50%;
            transform: translateX(-50%);
            height: 60%;
            width: 90%;
            display: flex;

            .c_l {
                flex: 48%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .item {
                    font-size: 28px;
                    color: #6f33fb;

                }
            }

            .c_r {
                flex: 52%;
                display: flex;

                .c_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;
                        justify-content: center;

                        p {
                            font-size: 20px;
                            line-height: 50px;
                            color: #6f33fb;
                            font-weight: 700;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            padding: 0 10px;
                        }
                    }
                }

                .c_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        text-align: center;
                    }
                }
            }
        }
    }


    // 活动商店
    .shop {
        width: 63%;
        margin: auto;
        position: relative;
        margin-top: 100px;

        .tit {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-bottom: 50px;
        }

        .shopBady {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .item {
                // flex: 20%;
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                img:nth-child(2) {
                    margin: 10px 0;
                }

                .item_number {
                    margin-bottom: 50px;
                    color: #fff;
                    font-size: 20px
                }

                .itemBady {
                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100%;
                    height: 54%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .itemBady_t {
                        flex: 27%;
                        width: 100%;
                        position: relative;

                        p {
                            font-size: 18px;
                            line-height: 48px;
                            padding-left: 45%;
                            color: #a40201;
                        }
                    }

                    .itemBady_c {
                        flex: 58%;
                        // height: 79%;
                        width: fit-content;
                        position: relative;

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 90px;
                            max-height: 100px;
                        }
                    }

                    .itemBady_b {
                        flex: 21%;
                        color: #a40201;
                        font-size: 15px;
                        line-height: 47px;
                        font-weight: bold;
                    }
                }

            }
        }
    }

    //活动说明
    .explain {
        width: 80%;
        margin: auto;
        margin-bottom: 50px;
        top: 60px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 4px 20px 0px #FFFFFF;
        border-radius: 40px;
        padding-bottom: 30px;

        // border: 20px solid rgba(255, 255, 255, 0.534);
        div:nth-child(1) {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 20px;
        }

        div:nth-child(2) {
            width: 90%;
            margin: 20px auto;
            // height: 60%;
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            // top: 20px;
            // bottom: 35px;

            p {
                line-height: 35px;
                font-size: 20px;
            }
        }
    }


    /deep/.footerWX {
        position: relative;
    }
}
</style>
